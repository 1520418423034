import { Component, Vue, Prop } from 'vue-property-decorator'
import 'intersection-observer'

Vue.directive('intersect', {
  bind(el, binding) {
    const { observer, index } = binding.value
    el.setAttribute('data-intersect', index)
    observer.observe(el)
  },
})

@Component
export default class Observer extends Vue {
  @Prop() options!: any

  instance = new IntersectionObserver(this.intersections, this.options)

  intersections(entries: any /*observer: IntersectionObserver*/) {
    entries.forEach(({ target, isIntersecting }: any) => {
      const intersect = target.getAttribute('data-intersect')
      //observer.unobserve(target)
      if (isIntersecting) this.$emit('activate', parseInt(intersect))
      else this.$emit('deactivate', parseInt(intersect))
    })
  }

  beforeDestroy() {
    if (this.instance) this.instance.disconnect()
  }

  render() {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        observer: this.instance,
      })
    )
  }
}
