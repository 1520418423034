import { fetchCollectionImages, fetchMetaTags } from '@/services'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Section extends Vue {
  reveal = false

  @Watch('$state.fetching')
  async onFetching(fetching: boolean) {
    this.reveal = false
    if (!fetching) {
      await this.$timer.defer(0.5)
      this.reveal = true
    }
  }

  async fetch(service: (options?: string) => Promise<any>, options?: string) {
    this.$state.fetching = true
    const { meta, params } = this.$route
    const data = await service(options)
    await fetchCollectionImages(data)
    await fetchMetaTags(meta?.page, params)
    this.$state.fetching = false
    return data
  }
}
