



















import { Component, Vue, Prop } from 'vue-property-decorator'
import Scroller from '@/components/organsims/Scroller.vue'
import Footer from '@/components/organsims/Footer.vue'
import Observer from '@/observable/Observer'

@Component({
  inheritAttrs: false,
  components: {
    Observer,
    Scroller,
    Footer,
  },
})
export default class Layout extends Vue {
  @Prop({ default: 'smooth' }) scrollMode!: string

  intersectionsStore: number[] = []
  activationStore: number[] = []

  intersected(index: string) {
    return this.intersectionsStore.indexOf(parseInt(index)) > -1
  }

  intersect(index: string) {
    return this.activationStore.indexOf(parseInt(index)) > -1
  }

  activate(index: number) {
    this.intersectionsStore.push(index)
    this.activationStore.push(index)
  }

  deactivate(index: number) {
    const indexOf = this.activationStore.indexOf(index)
    if (indexOf > -1) this.activationStore.splice(indexOf, 1)
  }

  scroll({ offset }: any) {
    if (offset.y > 0 && this.$state.needsScroll) this.$state.needsScroll = false
    else if (offset.y <= 0 && !this.$state.needsScroll) this.$state.needsScroll = true
  }

  mounted() {
    this.$state.smoothScroll = false
    this.$state.needsScroll = true
    this.$state.needsLogo = true
  }
}
