






























































import { Component, Vue } from 'vue-property-decorator'
import Socials from '@/components/molecules/Socials.vue'
import Links from '@/components/molecules/Links.vue'
import Logo from '@/components/atoms/icons/Logo.vue'
import i18n from '@/i18n'

@Component({
  components: {
    Socials,
    Links,
    Logo,
  },
})
export default class Footer extends Vue {
  get nav() {
    return this.$t('nav')
  }

  get year() {
    return new Date().getUTCFullYear().toString()
  }

  get needsNav() {
    return true
  }

  get path() {
    //const { BASE_URL } = process.env
    return `/${i18n.locale}`
  }
}
